const apiSettings = {
    dev_env: {
        env: "DEV", // PROD or DEV
        api_host_dev: "https://api.saphera.com",
        api_host_prod: "",
        api_path: "/api/v1/",
    },
    api() {
        // Set environment variables
        var host = "";
        if (this.dev_env.env === "DEV") {
            host = this.dev_env.api_host_dev;
        } else {
            host = this.dev_env.api_host_prod;
        }

        const route = host + this.dev_env.api_path;

        // return route;
        return {
            authenticate: route + "authenticate",
            checkToken: route + "checkToken",
            deployments: route + "deployments",
            depolyment_version: route + "deployment-versions",
        };
    },
};
export default apiSettings;
