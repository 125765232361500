import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
const rootElement = document.getElementById("root");

const theme = createTheme({
    components: {
        MuiPopover: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopper: {
            defaultProps: {
                container: rootElement,
            },
        },
    },
    palette,
    typography,
});

export default theme;
