import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import Alerts from "../components/Portal/Alerts/Alerts";
import Dashboard from "../components/Portal/Dashboard/Dashboard";
import CARSDeployment from "../components/Portal/Deployment/CARSDeployment";
import Deployment from "../components/Portal/Deployment/Deployment";
import PESDeployment from "../components/Portal/Deployment/PESDeployment";
import Wiki from "../components/Portal/WIki/Wiki";
import SSVersions from "../components/Portal/Deployment/SSVersions";
import DeploymentVersion from "../components/Portal/Deployment/DeploymentVersion";

class PortalRouter extends Component {
    render() {
        return (
            // <Routes>
            //     <Route path="/crm" exact element={<Dashboard />} />
            //     <Route path="/crm/alerts" exact element={<Alerts />} />
            //     <Route path="/crm/deployment/schoolstart" exact element={<Deployment />} />
            //     <Route path="/crm/deployment/cars" exact element={<CARSDeployment />} />
            //     <Route path="/crm/deployment/pes" exact element={<PESDeployment />} />
            //     <Route path="/crm/wiki" exact element={<Wiki />} />
            // </Routes>
            <Routes>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/alerts" exact element={<Alerts />} />
                {/* <Route path="/downloads/schoolstart" exact element={<Deployment />} /> */}
                <Route path="/downloads/schoolstart/:id" exact element={<DeploymentVersion />} />

                <Route path="/deployment/schoolstart" exact element={<SSVersions />} />
                <Route path="/deployment/cars" exact element={<CARSDeployment />} />
                <Route path="/deployment/pes" exact element={<PESDeployment />} />
                <Route path="/wiki" exact element={<Wiki />} />
            </Routes>
        );
    }
}

export default PortalRouter;
