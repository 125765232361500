import React from "react";
import { Link, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

// const useStyles = makeStyles((theme) => ({
//     copyright: {
//         margin: theme.spacing(4, 0, 6),
//     },
// }));

export default function CopyRight() {
    // const classes = useStyles();
    const theme = useTheme();

    return (
        <div
            style={{ marginTop: theme.spacing(4) }}
            // className={classes.copyright}
        >
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                {new Date().getFullYear()}{" "}
                <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                    Saphera Software
                </Link>
                {/* <br />
                {"Created By "}
                <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                    Saphera Software
                </Link> */}
            </Typography>
        </div>
    );
}
