import React from "react";
// import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import componentsStore from "../components/Portal/redux/componentsStore";
import theme from "../theme/index";
import AppRouter from "../routes/AppRouter";
// import { StyledEngineProvider } from "@mui/material/styles";

const store = componentsStore();

function App() {
    return (
        // <StyledEngineProvider injectFirst>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppRouter />
            </ThemeProvider>
        </Provider>
        // </StyledEngineProvider>
    );
}

export default App;
