import React from "react";
import { Typography } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

// const useStyles = makeStyles((theme) => ({
//     title: {
//         marginTop: theme.spacing(3),
//     },
// }));
export default function Dashboard() {
    // const classes = useStyles();
    // const theme = useTheme();

    return (
        <div>
            <Typography
                variant="h4"
                // className={classes.title}
                // sx={{ marginTop: theme.spacing(3) }}
            >
                Dashboard
            </Typography>
            <p>
                Welcome to the CRM Portal Dashboard. As with most portals, the purpose of a dashboard is to provide a
                quick overview of key metrics and provide easy access to most common portal features. We've included a
                graph to help visualize possible features.
            </p>
        </div>
    );
}
