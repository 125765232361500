import React from "react";
import {
    // makeStyles,
    Typography,
} from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//     title: {
//         marginTop: theme.spacing(3),
//     },
// }));
export default function Wiki() {
    // const classes = useStyles();

    return (
        <div>
            <Typography
                variant="h4"
                // className={classes.title}
            >
                Manual
            </Typography>
            <p>This page is reserved for user manuals.</p>
        </div>
    );
}
