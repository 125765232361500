import React from "react";

export default function Alerts() {
    return (
        <div>
            <h1>Alerts</h1>
            <p>
                This is a sample page. The idea is that CRM users can view alerts directly in the portal. Alerts could
                be messages from an administrator, another CRM user, a contact, etc.{" "}
            </p>
        </div>
    );
}
