import React from "react";
import { Route, Routes } from "react-router-dom";
// import Home from "../components/Home";
import Login from "../components/Login";
import Portal from "../components/Portal/Portal";
import Error404 from "../components/404";

// import withAuth from "../withAuth";

export default function AppRouter() {
    return (
        <Routes>
            {/* <Route path="/" exact component={Home} /> */}
            <Route path="/" exact element={<Login />} />
            {/* <Route path="/crm/*" component={withAuth(Portal)} /> */}
            <Route path="/crm/*" element={<Portal />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
}
