const Settings = {
    list: {
        signonBackgrounds: [
            {
                image: "/images/bg1.jpeg",
                alt: "background 1",
            },
            {
                image: "/images/bg2.jpg",
                alt: "background 2",
            },
            {
                image: "/images/bg3.jpg",
                alt: "background 3",
            },
        ],
    },
};

// Get a random signon background form the project settings file
export function RandomSignonBackground() {
    return getRandomBackground(Settings.list.signonBackgrounds);
}

// Randomly pick an image out of an array of images
function getRandomBackground(inList) {
    const index = Math.floor(Math.random() * inList.length);
    return inList[index].image;
}
