import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { RandomSignonBackground } from "./RandomBackgrounds";
import CopyRight from "./CopyRight";
import { useNavigate } from "react-router";
import apiSettings from "../helpers/apiSettings";

// function Copyright(props) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {"Copyright © "}
//             <Link color="inherit" href="https://mui.com/">
//                 Your Website
//             </Link>{" "}
//             {new Date().getFullYear()}
//             {"."}
//         </Typography>
//     );
// }

export default function Login(props) {
    const navigate = useNavigate();
    // const [email, setEmail] = React.useState("");
    // const [password, setPassword] = React.useState("");

    // const handleInputChange = (event) => {
    //     const { value, name } = event.target;
    //     if (name === "email") {
    //         setEmail(value);
    //     } else {
    //         setPassword(value);
    //     }
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get("email"),
    //         password: data.get("password"),
    //     });
    // };

    const onSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const credentials = { email: data.get("email"), password: data.get("password") };

        fetch(apiSettings.api().authenticate, {
            method: "POST",
            body: JSON.stringify(credentials),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    console.log("throwing!");
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                console.log(data);
                localStorage.setItem("user", JSON.stringify(data));
                navigate("/crm/");
            })
            .catch((err) => {
                console.error(err);
                alert("Error logging in please try again");
            });
    };

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: "url(" + RandomSignonBackground() + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div align="center">
                        <img src="/saphera-logo.png" alt="Saphera Logo" />
                    </div>
                    <Avatar sx={{ m: 1, mt: 5, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            // onChange={handleInputChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            // onChange={handleInputChange}
                        />
                        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                        </Grid>

                        <CopyRight sx={{ mt: 5 }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

// import React, { useState } from "react";
// // import Button from "@material-ui/core/Button";
// // import Grid from "@material-ui/core/Grid";
// // import Paper from "@material-ui/core/Paper";
// // import TextField from "@material-ui/core/TextField";
// // import Typography from "@material-ui/core/Typography";
// // import Box from "@material-ui/core/Box";
// // import Link from "@material-ui/core/Link";
// // import { makeStyles } from "@material-ui/core/styles";
// import {
//     Button,
//     Grid,
//     Paper,
//     TextField,
//     Typography,
//     Box,
//     Link,
//     Avatar,
//     FormControlLabel,
//     Checkbox,
// } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import { RandomSignonBackground } from "./RandomBackgrounds";
// import CopyRight from "../components/CopyRight";

// // const useStyles = makeStyles((theme) => ({
// //     root: {
// //         height: "100vh",
// //     },
// //     image: {
// //         backgroundImage: "url(" + RandomSignonBackground() + ")",
// //         backgroundRepeat: "no-repeat",
// //         backgroundColor: theme.palette.grey[50],
// //         backgroundSize: "cover",
// //         backgroundPosition: "left",
// //     },
// //     paper: {
// //         margin: theme.spacing(8, 4),
// //         display: "flex",
// //         flexDirection: "column",
// //         // alignItems: "center",
// //     },
// //     avatar: {
// //         margin: theme.spacing(1),
// //         backgroundColor: theme.palette.primary.main,
// //     },
// //     form: {
// //         width: "100%", // Fix IE 11 issue.
// //         marginTop: theme.spacing(1),
// //     },
// //     submit: {
// //         margin: theme.spacing(3, 0, 2),
// //     },
// //     logo: {
// //         padding: theme.spacing(4, 0, 8),
// //     },
// // }));

// export default function Login(props) {
//     // const classes = useStyles();

//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");

//     const handleInputChange = (event) => {
//         const { value, name } = event.target;
//         if (name === "email") {
//             setEmail(value);
//         } else {
//             setPassword(value);
//         }
//     };

//     const onSubmit = (event) => {
//         event.preventDefault();
//         const credentials = { email, password };

//         fetch("/api/authenticate", {
//             method: "POST",
//             body: JSON.stringify(credentials),
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     console.log("pushing");
//                     props.history.push("/crm/");
//                 } else {
//                     console.log("throwing!");
//                     const error = new Error(res.error);
//                     throw error;
//                 }
//             })
//             .catch((err) => {
//                 console.error(err);
//                 alert("Error logging in please try again");
//             });
//     };
//     return (
//         <div>
//             <Grid
//                 item
//                 xs={false}
//                 sm={4}
//                 md={7}
//                 sx={{
//                     backgroundImage: "url(https://source.unsplash.com/random)",
//                     backgroundRepeat: "no-repeat",
//                     backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                 }}
//             />
//             <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//                 <Box
//                     sx={{
//                         my: 8,
//                         mx: 4,
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                     }}
//                 >
//                     <div align="center">
//                         <img src="/saphera-logo.png" alt="Saphera Logo" />
//                     </div>
//                     <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
//                         <LockOutlinedIcon />
//                     </Avatar>
//                     <Typography component="h1" variant="h5">
//                         Sign in to your account
//                     </Typography>
//                     <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
//                         <TextField
//                             margin="normal"
//                             required
//                             fullWidth
//                             id="email"
//                             label="Email Address"
//                             name="email"
//                             autoComplete="email"
//                             autoFocus
//                         />
//                         <TextField
//                             margin="normal"
//                             required
//                             fullWidth
//                             name="password"
//                             label="Password"
//                             type="password"
//                             id="password"
//                             autoComplete="current-password"
//                         />
//                         <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
//                         <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
//                             Sign In
//                         </Button>
//                         <Grid container>
//                             <Grid item xs>
//                                 <Link href="#" variant="body2">
//                                     Forgot password?
//                                 </Link>
//                             </Grid>
//                             <Grid item>
//                                 <Link href="#" variant="body2">
//                                     {"Don't have an account? Sign Up"}
//                                 </Link>
//                             </Grid>
//                         </Grid>
//                         <Box mt={5}>
//                             <CopyRight />
//                         </Box>
//                     </Box>
//                 </Box>
//             </Grid>
//         </div>
//     );
// }
// // <Grid
// //     container
// //     component="main"
// //     // className={classes.root}
// // >
// //     <Grid
// //         item
// //         xs={false}
// //         sm={4}
// //         md={7}
// //         // className={classes.image}
// //     />
// //     <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
// //         <div
// //         // className={classes.paper}
// //         >
// //             <div
// //                 align="center"
// //                 // className={classes.logo}
// //             >
// //                 <img src="/saphera-logo.png" alt="Saphera Logo" />
// //             </div>

// //             <Typography variant="body2" color="textSecondary">
// //                 Sign in to your account
// //             </Typography>
// //             <form
// //                 // className={classes.form}
// //                 noValidate
// //                 onSubmit={onSubmit}
// //             >
// //                 <TextField
// //                     variant="outlined"
// //                     type="email"
// //                     name="email"
// //                     margin="normal"
// //                     fullWidth
// //                     label="Enter email"
// //                     autoComplete="email"
// //                     autoFocus
// //                     value={email}
// //                     required
// //                     onChange={handleInputChange}
// //                     id="email"
// //                 />
// //                 <TextField
// //                     variant="outlined"
// //                     type="password"
// //                     name="password"
// //                     margin="normal"
// //                     fullWidth
// //                     label="Enter password"
// //                     autoComplete="current-password"
// //                     autoFocus
// //                     value={password}
// //                     onChange={handleInputChange}
// //                     required
// //                     id="password"
// //                 />
// //                 {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
// //                 <Button
// //                     type="submit"
// //                     fullWidth
// //                     variant="contained"
// //                     color="primary"
// //                     // className={classes.submit}
// //                     value="submit"
// //                 >
// //                     Sign In
// //                 </Button>
// //                 <Grid container>
// //                     <Grid item xs>
// //                         <Link href="#" variant="body2">
// //                             Forgot password?
// //                         </Link>
// //                     </Grid>
// //                     <Grid item>
// //                         <Link href="/" target="_blank" variant="body2">
// //                             {"Don't have an account?"}
// //                         </Link>
// //                     </Grid>
// //                 </Grid>

// //                 <Box mt={5}>
// //                     <CopyRight />
// //                 </Box>
// //             </form>
// //         </div>
// //     </Grid>
// // </Grid>
