// import React from "react";
// import clsx from "clsx";
// import { connect } from "react-redux";
// import componentsStore from "../components/Portal/redux/componentsStore";
// import { drawerToggle } from "../components/Portal/redux/componentsActions";
// // import { Link } from "react-router-dom";

// import UserMenu from "../components/Portal/UserMenu";
//

// import { useTheme } from "@mui/material/styles";
// import Drawer from "@mui/material/Drawer";
// import AppBar from "@mui/material/AppBar";
// // import { Badge } from "@material-ui/core";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import MenuIcon from "@mui/icons-material/Menu";
// // import NotificationsIcon from "@material-ui/icons/Notifications";

// const drawerWidth = 240;

// // const useStyles = makeStyles((theme) => ({
// //     root: {
// //         display: "flex",
// //     },
// //     appBar: {
// //         zIndex: theme.zIndex.drawer + 1,
// //         transition: theme.transitions.create(["width", "margin"], {
// //             easing: theme.transitions.easing.sharp,
// //             duration: theme.transitions.duration.leavingScreen,
// //         }),
// //     },
// //     appBarShift: {
// //         marginLeft: drawerWidth,
// //         width: `calc(100% - ${drawerWidth}px)`,
// //         transition: theme.transitions.create(["width", "margin"], {
// //             easing: theme.transitions.easing.sharp,
// //             duration: theme.transitions.duration.enteringScreen,
// //         }),
// //     },
// //     menuButton: {
// //         marginRight: 36,
// //     },
// //     hide: {
// //         display: "none",
// //     },
// //     title: {
// //         flexGrow: 1,
// //     },
// //     drawer: {
// //         width: drawerWidth,
// //         flexShrink: 0,
// //         whiteSpace: "nowrap",
// //     },
// //     drawerOpen: {
// //         width: drawerWidth,
// //         transition: theme.transitions.create("width", {
// //             easing: theme.transitions.easing.sharp,
// //             duration: theme.transitions.duration.enteringScreen,
// //         }),
// //     },
// //     drawerClose: {
// //         transition: theme.transitions.create("width", {
// //             easing: theme.transitions.easing.sharp,
// //             duration: theme.transitions.duration.leavingScreen,
// //         }),
// //         overflowX: "hidden",
// //         width: theme.spacing(7) + 1,
// //         [theme.breakpoints.up("sm")]: {
// //             width: theme.spacing(9) + 1,
// //         },
// //     },
// //     toolbar: {
// //         display: "flex",
// //         alignItems: "center",
// //         justifyContent: "flex-end",
// //         padding: theme.spacing(0, 1),
// //         // necessary for content to be below app bar
// //         ...theme.mixins.toolbar,
// //     },
// //     content: {
// //         flexGrow: 1,
// //         padding: theme.spacing(3),
// //     },
// // }));

// const store = componentsStore();

// store.subscribe(() => {
//     console.log(store.getState());
// });

// // export default function
// const Header = (props) => {
//     // const classes = useStyles();
//     const theme = useTheme();

//     const toggleDraw = props.open;
//     const handleDrawerOpen = props.handleDrawerOpen;

//     return (
//         <div
//         // className={classes.root}
//         >
//             <AppBar
//                 elevation={0}
//                 position="fixed"
//                 // className={clsx(classes.appBar, {
//                 //     [classes.appBarShift]: toggleDraw,
//                 // })}
//             >
//                 <Toolbar>
//                     <IconButton
//                         color="inherit"
//                         aria-label="open drawer"
//                         onClick={handleDrawerOpen}
//                         edge="start"
//                         // className={clsx(classes.menuButton, {
//                         //     [classes.hide]: toggleDraw,
//                         // })}
//                     >
//                         <MenuIcon />
//                     </IconButton>
//                     <Typography
//                         variant="h6"
//                         noWrap
//                         // className={classes.title}
//                     >
//                         Saphera Portal
//                     </Typography>
//                     {/* <IconButton color="inherit" component={Link} to="/crm/alerts">
//                         <Badge badgeContent={1} color="secondary">
//                             <NotificationsIcon />
//                         </Badge>
//                     </IconButton> */}
//                     <UserMenu />
//                 </Toolbar>
//             </AppBar>
//             <Drawer
//                 variant="permanent"
//                 // className={clsx(classes.drawer, {
//                 //     [classes.drawerOpen]: toggleDraw,
//                 //     [classes.drawerClose]: !toggleDraw,
//                 // })}
//                 // classes={{
//                 //     paper: clsx({
//                 //         [classes.drawerOpen]: toggleDraw,
//                 //         [classes.drawerClose]: !toggleDraw,
//                 //     }),
//                 // }}
//             >
//                 <div
//                 // className={classes.toolbar}
//                 >
//                     <IconButton onClick={handleDrawerOpen}>
//                         {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
//                     </IconButton>
//                 </div>
//                 <Divider />
//                 <List>
//                     <Menu />
//                 </List>
//             </Drawer>
//         </div>
//     );
// };

// const mapStateToProps = (state) => {
//     return {
//         open: state.drawerOpen,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         handleDrawerOpen: () => {
//             dispatch(drawerToggle());
//         },
//     };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Header);

import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Menu from "./menu/Menu";
import CopyRight from "../components/CopyRight";
import PortalRouter from "../routes/PortalRouter";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

function DashboardContent() {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: "24px", // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: "36px",
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        Saphera Portal
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <Menu />
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
            >
                <Toolbar />
                <Container
                    maxWidth="lg"
                    sx={{
                        mt: 4,
                        mb: 4,
                        display: "flex",
                        minHeight: "80vh",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <PortalRouter />
                    <CopyRight />
                </Container>
            </Box>
        </Box>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
