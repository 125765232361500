import React, { useState } from "react";
import {
    Button,
    Collapse,
    Grid,
    Icon,
    List,
    ListItem,
    ListItemText,
    // makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: theme.spacing(4),
//     },
//     sideNav: {
//         minWidth: "240px",
//     },
//     logo: {
//         width: "240px",
//     },
//     nested: {
//         paddingLeft: theme.spacing(4),
//     },
//     main: {
//         paddingLeft: theme.spacing(4),
//     },
//     btn: {
//         marginTop: theme.spacing(2),
//         marginBottom: theme.spacing(2),
//     },
//     infoSec: {
//         margin: theme.spacing(3, 0),
//     },
// }));

export default function PESDeployment() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        // <Container maxWidth="lg">
        <Grid
            container
            // className={classes.root}
        >
            <Grid
                item
                md={3}
                sm={4}
                xs={4}
                align="center"
                // className={classes.sideNav}
            >
                <img
                    src="/pes-logo.jpg"
                    alt="client logo"
                    // className={classes.logo}
                />
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary={<Typography variant="subtitle1">Additional Links</Typography>} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                // className={classes.nested}
                            >
                                <a href="/">
                                    <ListItemText primary="Publisher Certificate" />
                                </a>
                            </ListItem>
                            <ListItem
                                button
                                // className={classes.nested}
                            >
                                <a href="/">
                                    <ListItemText primary="App Installer File" />
                                </a>
                            </ListItem>
                            <ListItem
                                button
                                // className={classes.nested}
                            >
                                <a href="/">
                                    <ListItemText primary="Package Bundle" />
                                </a>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Grid>
            <Grid
                item
                // className={classes.main}
            >
                <Typography variant="h4" gutterBottom>
                    PES Admin
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Version 1.0.8.0
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    School Start administration software
                </Typography>
                <Button
                    variant="contained"
                    // className={classes.btn}
                    color="primary"
                >
                    Get the app
                    <Icon fontSize="large">arrow_right</Icon>
                </Button>
                <a href="/">
                    <Typography variant="body2" gutterBottom>
                        Troubleshoot Installation
                    </Typography>
                </a>
                <Typography
                    variant="h5"
                    //  className={classes.infoSec}
                >
                    Application Information
                </Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Version</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">1.0.8.0</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Required Operating System</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">10.0.17134.0</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Architechtures</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">x86|x64</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Publisher</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">Saphera Software</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
        // </Container>
    );
}
