import React, {
    useState,
    // useEffect
} from "react";
import { connect } from "react-redux";

// import axios from "axios";
import { List } from "@mui/material";

import MenuItem from "./MenuItem";

const Menu = (props) => {
    const [menuList] = useState([
        {
            label: "Dashboard",
            depth: "1",
            path: "/crm",
            icon: "dashboard",
            section: 1,
        },
        {
            label: "Reports",
            depth: "1",
            path: "/crm/reports",
            icon: "assessment",
            section: 2,
        },
        {
            label: "Deployments",
            depth: "1",
            path: "/crm/deployment/schoolstart",
            icon: "get_app",
            section: 3,
        },
        {
            label: "Wiki",
            depth: "1",
            path: "/crm/wiki",
            icon: "menu_book",
            section: 4,
        },
    ]);

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     const signal = abortController.signal;

    //     // retrieving data from the API
    //     axios
    //         .get("/mock/menuList", { signal: signal })
    //         // axios.get("/mock/menuList")
    //         .then((response) => response)
    //         .then((data) => setMenuList(data.data));
    //     return () => {
    //         abortController.abort();
    //     };
    // }, []);

    return (
        <div>
            <List>
                {menuList.map((item) => {
                    return (
                        <div key={item.label}>
                            <MenuItem
                                openDrawer={props.open}
                                label={item.label}
                                depth={item.depth}
                                path={item.path}
                                icon={item.icon}
                                subMenu={item.subMenu ? item.subMenu : []}
                            />
                        </div>
                    );
                })}
            </List>
        </div>
    );
};

// export default Menu;
const mapStateToProps = (state) => {
    return {
        open: state.drawerOpen,
    };
};

export default connect(mapStateToProps)(Menu);
