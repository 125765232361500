// import { colors } from "@mui/material";

// const white = "#FFFFFF";

// export default {
//     primary: {
//         contrastText: white,
//         main: "#00afd1",
//         light: "#9ddeed",
//         dark: "#0cacd4",
//     },
//     secondary: {
//         contrastText: white,
//         main: "#282424",
//         light: "#534f4f",
//         dark: "#1c1919",
//     },
//     icon: "#50b94d",

//     divider: colors.grey[200],
// };
import { colors } from "@mui/material";

const white = "#FFFFFF";

const palette = {
    primary: {
        contrastText: white,
        main: "#00afd1",
        light: "#9ddeed",
        dark: "#0cacd4",
    },
    secondary: {
        contrastText: white,
        main: "#282424",
        light: "#534f4f",
        dark: "#1c1919",
    },
    icon: "#50b94d",

    divider: colors.grey[200],
};
export default palette;
