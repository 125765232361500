import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, ListItemText, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiSettings from "../../../helpers/apiSettings";
import VisibilityIcon from "@mui/icons-material/Visibility";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function SSVersions() {
    const navigate = useNavigate();
    const [downloadables, setDownloadables] = useState([]);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user")).token;

        fetch(apiSettings.api().deployments, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    console.log("throwing!");
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                // console.log(data);
                setDownloadables(data);
            })
            .catch((err) => {
                console.error(err);
            });

        return () => {};
    }, []);

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                School Start Downloads
            </Typography>
            <TableContainer component={Paper} style={{ marginTop: "40px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Programs</StyledTableCell>
                            <StyledTableCell>Note</StyledTableCell>
                            <StyledTableCell align="center">View</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {downloadables.map((d) => (
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover key={d.id}>
                                <TableCell component="th" scope="row">
                                    <ListItemText primary={d.name} secondary={d.description} />
                                </TableCell>
                                <TableCell>{d.notes}</TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        aria-label="download"
                                        onClick={() => navigate(`/crm/downloads/schoolstart/${d.id}`)}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {/* <TableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            hover
                            onClick={() => navigate("/crm/downloads/schoolstart")}
                        >
                            <TableCell component="th" scope="row">
                                School Start Admin PRODUCTION
                            </TableCell>
                            <TableCell align="right">Production Version</TableCell>
                        </TableRow>
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                School Start Admin STAGING
                            </TableCell>
                            <TableCell align="right">Staging Version</TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
