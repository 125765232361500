import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiSettings from "../../../helpers/apiSettings";

export default function DeploymentVersion({ deploymentId }) {
    const [open, setOpen] = useState(false);
    const [downloadVersions, setDownloadVersions] = useState({});
    const handleClick = () => {
        setOpen(!open);
    };

    const params = useParams();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user")).token;

        fetch(apiSettings.api().depolyment_version + `/${params.id}/latest`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    console.log("throwing!");
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                // console.log(data);
                setDownloadVersions(data);
            })
            .catch((err) => {
                console.error(err);
            });

        return () => {};
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                {/* <img src="/school-start-logo.png" alt="client logo" /> */}
                <img src={downloadVersions?.deploymentLogoUri} alt="client logo" />
            </Grid>
            <Grid item>
                <Typography variant="h4">
                    {/* School Start Admin PRODUCTION */}
                    {downloadVersions?.deploymentName}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {/* Version 1.0.8.0 */}
                    Version {downloadVersions?.version}
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px" }}>
                    {/* School Start Administration software */}
                    {downloadVersions?.notes}
                </Typography>
                <Button
                    variant="contained"
                    style={{ marginTop: "20px" }}
                    // className={classes.btn}
                    color="primary"
                    component={Link}
                    to={downloadVersions?.deploymentFileUri}
                >
                    Get the app
                    <Icon fontSize="large">arrow_right</Icon>
                </Button>
                {/* <a href="/">
                    <Typography variant="body2" gutterBottom>
                        Troubleshoot Installation
                    </Typography>
                </a>
                <Typography
                    variant="h5"
                    style={{ marginTop: "20px" }}
                    // className={classes.infoSec}
                >
                    Application Information
                </Typography>
                <Table>
                    <TableBody>
                         <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Version</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">1.0.8.0</Typography>
                            </TableCell>
                        </TableRow> 
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Required Operating System</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">10.0.17134.0</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Architechtures</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">x86 | x64</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingLeft: 0 }}>
                                <Typography variant="body1">Publisher</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">Saphera Software</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table> */}
            </Grid>
        </Grid>
        //     {/* </Container> */}
        // {/* </div> */}
    );
}
